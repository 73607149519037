import React, { SyntheticEvent } from 'react'
import { useModal } from '../../providers'
import RightArrow from '../../svg/RightArrow.svg'
import { Heading, Input } from '../Text'
import { LauncherWrapper } from './styled'
import { Button } from '../Button'

const { useState } = React

interface SignupLauncherProps {
  title: string
  subtitle: string
}

export const SignupLauncher = ({ title, subtitle }: SignupLauncherProps) => {
  const { openSignupModal } = useModal()

  return (
    <LauncherWrapper>
      <Heading my={0} level={4}>
        {title}
      </Heading>
      <Heading mb={3} level={5}>
        {subtitle}
      </Heading>
      <Button onClick={() => openSignupModal()} level={2}>
        Sign up
      </Button>
    </LauncherWrapper>
  )
}
