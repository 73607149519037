'use client'

import { useEffect } from 'react'
import { useModal } from '../providers'
import { getCookie } from '../utils'
import { usePathname } from 'next/navigation'

export function SignupModalLoader() {
  const { openSignupModal } = useModal()
  const signupSubmitted = getCookie('SK_SIGNUP_SUBMITTED')
  const signupClosed = getCookie('SK_SIGNUP_CLOSED_2')
  const pathname = usePathname()

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      !signupSubmitted &&
      !signupClosed &&
      !pathname.includes('welcome')
    ) {
      const modalTimeout = setTimeout(() => {
        openSignupModal()
      }, 1000)
      return () => clearTimeout(modalTimeout)
    }
  }, [])

  return null
}
