import styled, { css } from '@xstyled/styled-components'

interface WithVisible {
  visible: boolean
  colorTheme?: 'light' | 'dark'
}

interface WithColorTheme {
  colorTheme: 'light' | 'dark'
}

export const MainWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;

    ${theme.mediaQueries.mobile} {
      min-width: initial;
    }
  `}
`

export const SignupWrapper = styled.div<WithColorTheme>`
  ${({ theme, colorTheme }) => css`
    position: relative;
    padding: 6 6;

    ${colorTheme === 'dark' &&
    css`
      background-color: ${theme.colors.grays[8]};
      color: ${theme.colors.body[0]};
    `}

    h4,
    p {
      font-size: 4;
      font-weight: 300;
      line-height: 1.4em;
      margin: 0;
    }

    h4 {
      max-width: 320px;
    }

    ${theme.mediaQueries.mobile} {
      min-width: initial;
      padding: 5;
      h3 {
        font-size: 22px;
      }
      h4 {
        font-size: 15px;
        max-width: 290px;
      }
    }
  `}
`

export const ProductBadgeWrapper = styled.div`
  display: flex;
`

interface WithVisibleHV {
  visible: boolean
  highValue?: boolean
}

export const FieldsWrapper = styled.div<WithVisibleHV>`
  ${({ visible, highValue, theme }) => css`
    margin-top: 5;
    opacity: ${visible ? 1 : 0};
    pointer-events: ${visible ? 'inherit' : 'none'};
    transition: 0.2s;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 4;
    grid-column-gap: 3;

    .field--customization_details {
      grid-column: ${highValue ? '1 / 3' : 'unset'};
    }

    > div:nth-child(odd) {
      position: relative;
      > div:has(input:focus-visible) {
        ${theme.focus.left()}
      }
    }

    > div:nth-child(even) {
      position: relative;
      > div:has(input:focus-visible) {
        ${theme.focus.right()}
      }
    }

    > div {
      position: relative;
      > div:has(textarea:focus-visible) {
        ${theme.focus.left()}
      }
    }

    #phone {
      outline: none;
    }

    select {
      height: 42px;
      max-width: initial;
      width: 100%;
    }

    button[type='submit'],
    .field--message {
      grid-column: 1 / 3;
    }

    ${theme.mediaQueries.mobile} {
      display: flex;
      flex-direction: column;

      button[type='submit'],
      .field--message {
        grid-column: auto;
      }
    }
  `}
`

export const SignupFieldsWrapper = styled.div<WithVisible>`
  ${({ visible, theme, colorTheme }) => css`
    margin-top: 8;
    transition: 0.2s;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 4;
    grid-column-gap: 3;

    > div:nth-child(1),
    > div:nth-child(2) {
      opacity: ${visible ? 1 : 0};
      pointer-events: ${visible ? 'inherit' : 'none'};
    }

    > div:nth-child(odd) {
      position: relative;
      > div:has(input:focus-visible) {
        ${theme.focus.left()}
      }
    }

    > div:nth-child(even) {
      position: relative;
      > div:has(input:focus-visible) {
        ${theme.focus.right()}
      }
    }

    > div {
      position: relative;
      > div:has(textarea:focus-visible) {
        ${theme.focus.left()}
      }
    }

    > button {
      text-align: center;
      color: ${theme.colors.body[0]};
      background-color: ${theme.colors.bodyMain};

      ${colorTheme === 'dark' &&
      css`
        color: ${theme.colors.grays[8]};
        background-color: ${theme.colors.body[0]};
      `}
    }

    #phone {
      outline: none;
    }

    select {
      height: 42px;
      max-width: initial;
      width: 100%;
    }

    > div:last-child {
      color: ${theme.colors.bodyMain};
      opacity: 0.5;

      ${colorTheme === 'dark' &&
      css`
        color: ${theme.colors.grays[4]};
      `}

      a {
        color: #0100ff;
        text-decoration: underline;
        text-transform: uppercase;

        ${colorTheme === 'dark' &&
        css`
          filter: invert(1);
        `}
      }
    }

    ${theme.mediaQueries.mobile} {
      margin-top: 5;
      display: flex;
      flex-direction: column;
      grid-row-gap: 3;
      font-weight: 400;
    }
  `}
`

export const InputWrapper = styled.div<WithColorTheme>`
  ${({ theme, colorTheme }) => css`
    display: flex;
    gap: 3;
    align-items: center;

    ${colorTheme === 'dark' &&
    css`
      label {
        color: ${theme.colors.body[4]};
      }

      input[type='checkbox'] {
        background-color: transparent;
      }
    `}

    > h5,
    > div > h5 {
      font-style: italic;
      position: absolute;
      top: -1;
      right: 0;
      font-weight: 300;
    }
    & > div:nth-child(1) {
      input[type='checkbox'] {
        background-color: transparent;
        margin-top: 4;
        outline: 1px solid ${theme.colors.grays[5]};

        border: 1px solid ${theme.colors.body[2]};

        ${colorTheme === 'dark' &&
        css`
          border: 1px solid ${theme.colors.grays[8]};
        `}

        &:checked {
          background-color: grays.5;
        }
      }
    }
    & > div:nth-child(2) {
      flex: 1;
      input {
        background-color: transparent;
        ${colorTheme === 'dark' &&
        css`
          color: ${theme.colors.body[0]};
        `}
      }
      &.field > div > div {
        border: 1px solid ${theme.colors.grays[5]};
      }
      &.field--disabled {
        > h5 {
          display: none;
        }
      }
      &.field--disabled > div {
        opacity: 0.25;
      }
    }
    &.last-row {
      margin-bottom: 4;
    }
    ${theme.mediaQueries.mobile} {
      input:not([type='checkbox']) {
        height: 42px;
        padding: 12px 6px;
      }
    }
  `}
`

export const SuccessWrapper = styled.div<WithVisible>`
  ${({ visible }) => css`
    opacity: ${visible ? 1 : 0};
    pointer-events: ${visible ? 'inherit' : 'none'};
    transition: 0.2s;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
  `}
`

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const TextWrapper = styled.div`
  ${({ theme }) => css`
    font-size: 5;
    font-weight: 200;
    font-style: italic;
    line-height: 1.3;
    display: inline;
    max-width: 100%;
  `}
`

export const ConsentWrapper = styled.div`
  ${({ theme }) => css`
    font-size: 6;
    font-weight: 200;
    line-height: 1.3;
    display: inline;
    max-width: 360px;
    .links-wrapper {
      display: flex;
      gap: 2;
      margin-top: 1;
    }
  `}
`
